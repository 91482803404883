import { Footer } from '@everlywell/leaves';
import React, { Ref } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { handleIterableSubscription } from 'utils/iterableSubscribe';
interface Props {
  showFDADisclaimer?: boolean;
  showAvailableForSelectConditionsDisclaimer?: boolean;
  showMedicareDisclaimer?: boolean;
}

const FooterContainer = React.forwardRef(
  (
    {
      showFDADisclaimer = false,
      showAvailableForSelectConditionsDisclaimer = false,
      showMedicareDisclaimer = false,
    }: Props,
    ref: Ref<HTMLDivElement>,
  ) => {
    const handleSubscribeSubmit = ({
      subscribeEmail,
    }: {
      [key: string]: string;
    }) => {
      handleIterableSubscription(subscribeEmail);
    };

    const handleOnCLickLink = (label: string) => {
      ewAnalytics.track({
        event: ANALYTICS.EVENTS.CLICKED_LINK,
        data: {
          sectionHeader: 'Footer',
          label,
        },
      });
    };

    return (
      <div ref={ref}>
        <Footer
          onSubscribeSubmit={handleSubscribeSubmit}
          showFDADisclaimer={showFDADisclaimer}
          onClickLink={handleOnCLickLink}
          showAvailableForSelectConditionsDisclaimer={
            showAvailableForSelectConditionsDisclaimer
          }
          showMedicareDisclaimer={showMedicareDisclaimer}
        />
      </div>
    );
  },
);

export default FooterContainer;
